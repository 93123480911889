import {
    isValidElement,
    type PropsWithChildren,
    type ReactNode,
    useEffect,
} from 'react';
import { clsx } from 'clsx';
import { InfoCard, useInfoCard } from '../../InfoCard';
import s from './info-card-template.module.scss';

interface InfoCardTemplateProps {
    infoCard?: ReactNode;
    className?: string;
    defaultOpen?: boolean;
    defaultHideToggle?: boolean;
}

export function InfoCardTemplate({
    children,
    infoCard,
    defaultOpen = false,
    className,
    defaultHideToggle,
}: PropsWithChildren<InfoCardTemplateProps>): ReactNode {
    const { open, setToggleDisabled, setOpen } = useInfoCard();

    useEffect(() => {
        if (defaultOpen) {
            setOpen(defaultOpen);
        }
    }, [setOpen, defaultOpen]);

    const classes = clsx(
        s.infoCardTemplate,
        {
            [s.open]: open,
        },
        className,
    );

    useEffect(() => {
        setToggleDisabled(Boolean(defaultHideToggle));
    }, [defaultHideToggle, setToggleDisabled]);

    return (
        <div className={classes}>
            <div className={s.contentArea}>{children}</div>
            {isValidElement(infoCard) && <InfoCard>{infoCard}</InfoCard>}
        </div>
    );
}
